<template>
    <div class="DialogReportPaid__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">הוספת משתמש</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <b-radio v-model="radio" name="name" native-value="manual">
                    ידני
                </b-radio>
                <b-radio v-model="radio" name="name" native-value="file">
                    מקובץ
                </b-radio>
                <div class="DialogReportPaid__Component__Loading">
                    <div v-if="radio === 'manual'">
                        <b-field label="שם פרטי">
                            <b-input v-model="model.privateName"></b-input>
                        </b-field>
                        <b-field label="שם משפחה">
                            <b-input v-model="model.lastName"></b-input>
                        </b-field>
                        <b-field label="דואל">
                            <b-input v-model="model.userName"></b-input>
                        </b-field>
                        <!-- <b-field label="סיסמה">
                            <b-input type="password" password-reveal v-model="model.password"></b-input>
                        </b-field> -->
                        <b-field label="טלפון">
                            <b-input v-model="model.phone1"></b-input>
                        </b-field>
                        <b-field label="כתובת">
                            <b-input v-model="model.address"></b-input>
                        </b-field>
                        <b-field label="סטטוס">
                            <b-select v-model="model.status">
                                <option value="1">1</option>
                                <option value="0">0</option>
                            </b-select>
                        </b-field>
                    </div>
                    <div v-else>
                        <b-field label="פורמט להורדה">
                            <b-button @click="downloadUsersFormat">הורד</b-button>
                        </b-field>
                        <b-field label="בחירת קובץ (יש לטעון קובץ בפורמט שניתן בלבד!)">
                            <b-upload v-model="file" class="file-label">
                                <span class="file-cta">
                                    <b-icon class="file-icon" icon="upload"></b-icon>
                                    <span class="file-label">בחירת קובץ</span>
                                </span>
                                <span class="file-name" v-if="file">
                                    {{ file.name }}
                                </span>
                            </b-upload>
                        </b-field>
                    </div>
                    <b-field style="margin-top: 10px;" label="שיוך לקורס (חובה)">
                        <div class="courses">
                            <div style="display: flex; gap: 12px" v-for="course in courses" class="course"
                                :key="course.id">
                                <input type="checkbox" :name="'course' + course.id" :id="'course' + course.id"
                                    v-model="course.isSelected">
                                <label style="cursor: pointer;" :for="'course' + course.id">{{ course.name }}</label>
                            </div>
                        </div>
                    </b-field>
                    <b-field label="שיוך למסלול">
                        <div class="tracks">
                            <div style="display: flex; gap: 12px" v-for="track in tracks" class="track" :key="track.id">
                                <input type="checkbox" :name="'track' + track.id" :id="'track' + track.id"
                                    v-model="track.isSelected">
                                <label style="cursor: pointer;" :for="'track' + track.id">{{ track.name }} בקורס: {{
                    track.courseName
                }}</label>
                            </div>
                        </div>
                    </b-field>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import { ToastProgrammatic as Toast } from "buefy";
import UserService from "../services/UserService";
import CoursesService from "../services/CoursesService";
import { saveAs } from "file-saver";

export default {
    name: "DialogReportDriverAssign",
    props: ["reportNumbers", "reports", "onSuccess", "store"],
    components: {
    },
    computed: {
        courseId() {
            return this.store.state.auth.course?.id;
        },
    },
    created() {
        this.getCourses();
        this.getTracks();
    },
    data() {
        return {
            submitted: false,
            isLoading: false,
            courses: null,
            tracks: null,
            radio: 'manual',
            file: null,
            model: {
                privateName: null,
                lastName: null,
                userName: null,
                // password: null,
                phone1: null,
                address: null,
                status: 1
            }
        };
    },
    methods: {
        getCourses() {
            CoursesService.getCourses(true).then((r) => {
                this.courses = r.data;
            });
        },
        getTracks() {
            CoursesService.getTracks().then((r) => {
                this.tracks = r.data.items;
            });
        },
        downloadUsersFormat() {
            CoursesService.downloadUsersFormat({})
                .then((r) => {
                    this.saveFile(r.data, "פורמט משתמשים ממערכת SimAdmin");
                })
        },
        saveFile(data, filename) {
            const blob = new Blob([data], { type: this.excelMimeType });
            saveAs(blob, `${filename}.xlsx`);
        },
        convertFileToByteArray(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsArrayBuffer(file); // Read the file as an ArrayBuffer

                reader.onload = (event) => {
                    const byteArray = new Uint8Array(event.target.result); // Convert to Uint8Array
                    resolve(byteArray);
                };

                reader.onerror = (error) => {
                    reject(error);
                };
            });
        },
        save() {
            this.isLoading = true;
            if (this.radio === 'manual') {
                UserService.addUser({
                    ...this.model,
                    courseIds: this.courses.filter((c) => c.isSelected).map((course) => course.id),
                    trackIds: this.tracks.filter((t) => t.isSelected).map((track) => track.id),
                })
                    .then(() => {
                        Toast.open({
                            type: "is-success",
                            message: "הפעולה בוצעה!",
                            duration: 4000,
                        });
                        this.$emit("close");
                        this.onSuccess();
                    }).catch((err) => {
                        let message = '';
                        if (err.response.data.includes('Similar')) {
                            message = 'המשתמש קיים כבר במערכת.'
                        } else {
                            message = "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.";
                        }
                        Toast.open({
                            type: "is-danger",
                            message: message,
                            duration: 8000,
                        });
                    }).finally(() => {
                        this.isLoading = false;
                        this.$emit("customEvent")
                    });
            } else {
                const formData = new FormData();
                formData.append('file', this.file);
                var queryCourseString = "";
                var queryTrackString = "";
                this.courses.filter((c) => c.isSelected).map((course) => course.id).map((q) => queryCourseString += `${q},`);
                this.tracks.filter((t) => t.isSelected).map((track) => track.id).map((t) => queryTrackString += `${t},`);
                CoursesService.importUsers(queryCourseString, queryTrackString, formData)
                    .then(() => {
                        Toast.open({
                            type: "is-success",
                            message: "הפעולה בוצעה!",
                            duration: 4000,
                        });
                        this.$emit("close");
                        this.onSuccess();
                    }).catch((err) => {
                        console.log(err);
                    }).finally(() => {
                        this.isLoading = false;
                        this.$emit("customEvent")
                    })
            }
        },
        onIsCreateTask() {
            if (!this.isCreateTask) {
                this.isModalOpen = true;
            }
        },
        onTaskModelClose() {
            this.isModalOpen = false;
            this.isCreateTask = false;
        },
    },
};
</script>

<style scoped lang="scss">
.courses,
.tracks {
    max-height: 200px;
    margin: 10px 0;
    overflow: auto;
}
</style>
